import axios from 'axios'
import pubsub from 'pubsub-js'
import React from 'react'
import { withRouter } from 'react-router'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Collapse, Input, Row, Table } from 'reactstrap'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import ContentWrapper from '../Layout/ContentWrapper'
class ProcessWages extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      importId: '',
      matched: [],
      notMatched: [],
      notInCSV: [],
      importMonth: '',
      importYear: '',
      importName: '',
      department: '',
      team: '',
      proccesType: '',
      accordionState: [true, true, false]
    }
  }

  processData = (data) => {
    var importId = data.import_id
    var importMonth = data.import_month
    var importYear = data.import_year
    var importName = data.import_name
    var department = data.department
    var team = data.team
    var proccesType = data.process_type

    var matched = data.data
      .filter((elem) => elem.userdata != null && elem.isInCSV == true)
      .map((e) => {
        return {
          ...e,
          base: parseFloat(e.base),
          bruto: parseFloat(e.bruto),
          provision: parseFloat(e.provision),
          incentive: parseFloat(e.incentive),
          compensation: parseFloat(e.compensation),
          net: parseFloat(e.net),
          tax: parseFloat(e.tax),
          work_hours: e.work_hours,
          trust: parseFloat(e.trust)
        }
      })
    var notMatched = data.data.filter((elem) => elem.userdata == null && elem.isInCSV == true)
    var notInCSV = data.data.filter((elem) => elem.isInCSV == false)
    this.setState({
      importId,
      matched,
      notMatched,
      importMonth,
      importYear,
      importName,
      department,
      notInCSV,
      team,
      proccesType
    })
  }

  async componentDidMount() {
    if (this.props.location.params && this.props.location.params.importData) {
      this.processData(this.props.location.params.importData)
    } else if (this.props.match.params && this.props.match.params.wageImportID) {
      try {
        const { type } = this.props.location.reprocessType || {}
        let importData = await axios.get(`salaryimport/${type}/reprocess/${this.props.match.params.wageImportID}`)
        if (importData.data.success) {
          this.processData(importData.data)
        } else {
          pubsub.publish('showNotification', {
            message: importData.data.message,
            type: 'error'
          })
          this.props.history.push('/manageWages')
        }
      } catch (e) {
        this.props.history.push('/dashboard')
      }
    } else {
      this.props.history.push('/dashboard')
    }
  }

  toggleAccordion = (id) => {
    let accordionState = this.state.accordionState.map((val, i) => {
      return id === i ? !val : this.state.oneAtATime ? false : val
    })
    this.setState({
      accordionState
    })
  }

  _processData = () => {
    Swal.fire({
      title: 'A jeni i sigurt?',
      text: `Të dhënat do të importohen per muajin e zgjedhur dhe të gjithë përdoruesit do të njoftohen për pagën e procesuar, nese jeni duke procesuar për më shumë se një herë për muajin e caktuar për te njejëtin team, atëherë do të njoftohen vetëm përdoruesit të cilëve u ka ndryshuar rroga baze`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Po, vazhdo.',
      cancelButtonText: 'Jo.'
    }).then((result) => {
      if (result.value) {
        this.setState({ isLoading: true })
        var processData = {
          target_month: this.state.importMonth,
          target_year: this.state.importYear,
          import_file: this.props.match.params.wageImportID,
          department: this.state.department,
          team: this.state.team,
          salary_data: this.state.matched
        }
        axios
          .post(`salaryimport/team/process`, processData)
          // .post(`salaryimport/${this.state.proccesType}/process`, processData)

          .then((importResult) => {
            if (importResult.data.success) {
              pubsub.publish('showNotification', {
                message: 'Procesimi përfundoj me sukses',
                type: 'success'
              })
              this.props.history.push('/manageWages')
            } else {
              pubsub.publish('showNotification', {
                message: 'Gabim gjatë importimit të të dhënave',
                type: 'error'
              })
            }
            this.setState({ isLoading: false })
          })
          .catch((e) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë importimit të të dhënave',
              type: 'error'
            })
            this.setState({ isLoading: false })
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        pubsub.publish('showNotification', {
          message: 'Procesimi u anulua',
          type: 'error'
        })
      }
    })
  }

  render() {
    return (
      <ContentWrapper>
        <div className='content-heading d-flex justify-content-between'>
          <p>
            Verifiko pagat: {this.state.importName} - Muaji {this.state.importMonth}, Viti {this.state.importYear}
          </p>
          <div>
            <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
              <span className='btn-label'>
                <i className='fa fa-arrow-left' />
              </span>{' '}
              Prapa
            </Button>
          </div>
        </div>

        <Row>
          <Col lg={12} className={this.state.isLoading ? 'whirl standard' : ''}>
            {this.state.notMatched.length > 0 && (
              <Card outline color='danger' className='mb-3'>
                <CardHeader onClick={() => this.toggleAccordion(0)} className='text-white bg-danger cursor-pointer'>
                  <em className={`fa-fw fas icon-arrow-${this.state.accordionState[0] ? 'up' : 'down'} mr-2`}></em>
                  Të dhënat mungojnë
                </CardHeader>
                <Collapse isOpen={this.state.accordionState[0]}>
                  <CardBody>
                    <CardTitle>Për ID Numrat e mëposhtëm mungojnë të dhënat në sistem</CardTitle>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Baza</th>
                          <th>Bonus</th>
                          <th>Incentiva</th>
                          <th>Kompenzimi</th>
                          <th>Totali</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.notMatched.map((elem) => {
                          return (
                            <tr>
                              <td>
                                <div className='media'>
                                  <div className='media-body py-2'>
                                    <div className='text-bold'>{elem.id_number}</div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <Input disabled value={elem.baseSalary} />
                              </td>
                              <td>
                                <Input disabled value={elem.bonus} />
                              </td>
                              <td>
                                <Input disabled value={elem.incentive} />
                              </td>
                              <td>
                                <Input disabled value={elem.compensation} />
                              </td>
                              <td>
                                <Input disabled value={elem.total} />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Collapse>
              </Card>
            )}
            {this.state.notInCSV.length > 0 && (
              <Card outline color='warning' className='mb-3'>
                <CardHeader onClick={() => this.toggleAccordion(1)} className='text-white bg-warning cursor-pointer'>
                  <em className={`fa-fw fas icon-arrow-${this.state.accordionState[1] ? 'up' : 'down'} mr-2`}></em>
                  Të dhënat e mungojnë
                </CardHeader>
                <Collapse isOpen={this.state.accordionState[1]}>
                  <CardBody>
                    <CardTitle>Të dhënat më poshtë gjinden në sistem, por jo në fajllin e pagave</CardTitle>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Emri Mbiemri / ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.notInCSV.map((elem) => {
                          return (
                            <tr>
                              <td>
                                <div className='media'>
                                  <img
                                    className='align-self-center mr-2 rounded-circle img-thumbnail thumb48'
                                    src={'/img/blank_profile.png'}
                                    alt={`${elem.userdata.first_name} ${elem.userdata.last_name}`}
                                  />
                                  <div className='media-body py-2'>
                                    <div className='text-bold'>
                                      {`${elem.userdata.first_name} ${elem.userdata.last_name}`}
                                      <div className='text-sm text-muted'>{elem.id_number}</div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Collapse>
              </Card>
            )}
            {this.state.matched.length > 0 && (
              <Card outline color='success' className='mb-3'>
                <CardHeader onClick={() => this.toggleAccordion(2)} className='text-white bg-success cursor-pointer'>
                  {' '}
                  <em className={`fa-fw fas icon-arrow-${this.state.accordionState[2] ? 'up' : 'down'} mr-2`}></em>
                  Të dhënat e përputhura
                </CardHeader>
                <Collapse isOpen={this.state.accordionState[2]}>
                  <CardBody>
                    <CardTitle>Të dhënat më poshtë janë përputhur. Ju lutem rishikojini edhe një herë</CardTitle>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Emri Mbiemri / ID</th>
                          <th>Baza</th>
                          <th>Bonusi</th>
                          <th>Incentiva</th>
                          <th>Kompenzimi</th>
                          <th>Totali</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.matched.map((elem) => {
                          return (
                            <tr>
                              <td>
                                <div>
                                  <img
                                    className='align-self-center mr-2 rounded-circle img-thumbnail thumb48'
                                    src={'/img/blank_profile.png'}
                                    alt={`${elem.userdata.first_name} ${elem.userdata.last_name}`}
                                  />
                                  <div className='media-body py-2'>
                                    <div className='text-bold'>
                                      {`${elem.userdata.first_name} ${elem.userdata.last_name}`}
                                      <div className='text-sm text-muted'>{elem.id_number}</div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <Input disabled value={elem.baseSalary} />
                              </td>
                              <td>
                                <Input disabled value={elem.bonus} />
                              </td>
                              <td>
                                <Input disabled value={elem.incentive} />
                              </td>
                              <td>
                                <Input disabled value={elem.compensation} />
                              </td>
                              <td>
                                <Input disabled value={elem.total} />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Collapse>
              </Card>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className='form-group row float-right'>
              <div className='col-md-10'>
                <button className='btn btn-info' onClick={this._processData}>
                  Proceso
                </button>
                {/* {(this.state.notInCSV.length > 0 || this.state.notMatched.length > 0) ?
                            <button disabled className="btn btn-info">
                                Proceso
                            </button>
                            :
                            <button className="btn btn-info" onClick={this._processData}>
                                Proceso
                            </button>
                            } */}
              </div>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    )
  }
}

export default withRouter(ProcessWages)
